import axios from 'axios';
import clsx from 'clsx';
import { Button } from '../ui/Button';
import {
  FormError,
  formAction$,
  reset,
  useForm,
  valiForm$
} from '@modular-forms/qwik';
import { Input } from '../ui/Input';
import { RequestEventCommon } from '@builder.io/qwik-city';
import {
  type Input as ValibotInput,
  email,
  minLength,
  object,
  optional,
  string
} from 'valibot';

export type SigninForm = ValibotInput<typeof signinSchema>;

export const signinSchema = object({
  email: string([
    minLength(1, 'Por favor informe seu e-mail'),
    email('E-mail inválido')
  ]),
  password: string([
    minLength(1, 'Por favor informe a senha'),
    minLength(8, 'Mínimo de 8 caracteres')
  ]),
  redirectUrl: optional(string())
});

import { component$, useSignal, useTask$ } from '@builder.io/qwik';
import { signInWithEmail } from '../../lib/auth';
import { useAuthDataLoader } from './utils';

export const handleSigninAction = async (
  token: string,
  values: SigninForm,
  { cookie }: RequestEventCommon
) => {
  const signinResponse = await axios
    .post(`${global.ENV.PUBLIC_API_URL}/kratos/auth/sign_in`, values, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    .catch(error => {
      if (!error.response) {
        throw new FormError<SigninForm>(
          'Um erro inesperado ocorreu. Por favor, entre em contato com o suporte!'
        );
      }

      if (Array.isArray(error.response.data.errors)) {
        throw new FormError<SigninForm>('', error.response.data.errors);
      }
    });

  const setCookie = signinResponse!.headers['set-cookie'];

  if (setCookie) {
    for (const cookieStr of setCookie) {
      const [name, value] = cookieStr.split('=').map(val => val.trim());
      cookie.set(name, value);
    }
  }
};

export const useSigninFormAction = formAction$<SigninForm>(
  async (values, ctx) => {
    try {
      const auth = await signInWithEmail(
        values.email,
        values.password,
        ctx.url.hostname
      );

      if (!auth.user) {
        throw 'No user';
      }

      for (const cookie of auth.cookies) {
        cookie.value
          ? ctx.cookie.set(cookie.name, cookie.value, cookie.options)
          : ctx.cookie.delete(cookie.name, cookie.options);
      }
    } catch {
      throw new FormError<SigninForm>('', {
        email: 'E-mail ou senha inválido',
        password: 'E-mail ou senha inválido'
      });
    }
  },
  valiForm$(signinSchema)
);

export const SigninEmailForm = component$(
  ({ onSuccess$, reload }: { onSuccess$?: () => void; reload?: boolean }) => {
    const { value: data } = useAuthDataLoader();

    const initialState = useSignal({
      email: '',
      password: '',
      redirectUrl: undefined
    });

    const action = useSigninFormAction();

    const [state, { Form, Field }] = useForm<SigninForm>({
      action,
      loader: initialState,
      validateOn: 'touched',
      validate: valiForm$(signinSchema)
    });

    useTask$(({ track }) => {
      track(() => state.submitting);

      const status = track(() => action.status);

      if (state.invalid || status !== 200 || typeof onSuccess$ !== 'function') {
        return;
      }

      if (action.value?.response?.status === 'error') {
        return;
      }

      reset(state);

      if (reload) {
        window.location.href = window.location.href.split('#')[0];
      } else {
        onSuccess$();
      }
    });

    return (
      <div class="relative flex w-full flex-col items-center justify-start gap-5 mt-9">
        <div class="flex flex-col gap-7 self-stretch">
          <Form class="flex w-full flex-col gap-5">
            <Field name="email">
              {(field, props) => (
                <Input
                  {...props}
                  class={clsx('focus:placeholder-neutral-300')}
                  autoComplete="username"
                  disabled={state.submitting}
                  errorText={field.touched && state.invalid ? field.error : ''}
                  placeholder="Seu email*"
                  size="lg"
                  state={
                    field.touched && state.invalid
                      ? field.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  type="email"
                  value={field.value}
                />
              )}
            </Field>
            <Field name="password">
              {(field, props) => (
                <Input
                  {...props}
                  class={clsx('focus:placeholder-neutral-300')}
                  autoComplete="current-password"
                  disabled={state.submitting}
                  errorText={field.touched && state.invalid ? field.error : ''}
                  placeholder="Sua senha*"
                  size="lg"
                  state={
                    field.touched && state.invalid
                      ? field.error
                        ? 'error'
                        : 'success'
                      : 'default'
                  }
                  value={field.value}
                  password
                />
              )}
            </Field>
            <Field name="redirectUrl">
              {(field, props) => (
                <input
                  {...props}
                  disabled={state.submitting}
                  type="hidden"
                  value={field.value}
                />
              )}
            </Field>
            <a
              class="self-end text-xs text-slate-500"
              href="/conta/senha"
              target="_blank"
              // href={`${currentHref.value}#!/recovery`}
            >
              {data.signin.forgotPasswordTitle}
            </a>

            <Button
              class="self-center w-full"
              buttonText="Entrar com e-mail"
              disabled={state.submitting}
              loading={state.submitting}
              loadingText="Entrando..."
              size="lg"
              title="Entrar"
              type="submit"
              variant="brand"
            />
          </Form>
        </div>
      </div>
    );
  }
);
